.Footer {
    margin-top: 1rem;
    float: bottom;
}

.copyright {
    float: right;
}

.footerContainer {
    padding: 2rem;
}